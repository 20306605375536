import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {

  static targets = [

  ]

  static values = {
    item: Number, quantity: Number, direction: String
  }
  connect() {

  }

  async updateQuantity({ params: { item, direction } }) {
      const url = '/cart_items/' + item + '?direction=' + direction
      const req = new FetchRequest("patch", url, {
          contentType: "application/json",
          responseKind: "json"
      });
      const response = await req.perform();
      const data = await response.json;

      console.log(data);

      if (response.response.status === 422){
          return alert("Critical Error")
      } else {
          document.getElementById('quantity_item_' + item).value = data.quantity
          document.getElementById('item_total_' + item).innerHTML = '$' + Number(data.item_total).toFixed(2)
          document.getElementById('cart_items_total').innerHTML = '$' + Number(data.cart.items_total).toFixed(2)
          document.getElementById('cart_grand_total').innerHTML = '$' + Number(data.cart.grand_total).toFixed(2)
          document.getElementById('cart_items_total_discount').innerHTML = '-$' + Number(data.cart.total_discount).toFixed(2)
      }
  }

  async deleteItem({ params: { item } }) {
      const url = '/cart_items/' + item
      const req = new FetchRequest("delete", url, {
          contentType: "application/json",
          responseKind: "json"
      });
      const response = await req.perform();
      const data = await response.json;

      if (response.response.status === 422){
          return alert("Critical Error")
      } else {
          document.getElementById('cart_item_' + item).remove()
          document.getElementById('cart_items_total').innerHTML = '$' + Number(data.cart.items_total).toFixed(2)
          document.getElementsByClassName('modal-backdrop')[0].remove()
      }
  }

  async updateQuantities() {
        const items = document.getElementsByClassName('qty_item')
        const quantities = []
        for (let i = 0; i < items.length; i++) {
            quantities.push({id: items[i].id.split('_')[2], quantity: items[i].value})
        }
        const url = '/cart_items/update_quantities'
        const req = new FetchRequest("post", url, {
            contentType: "application/json",
            responseKind: "json",
            body: JSON.stringify(quantities)
        });
        const response = await req.perform();
        const data = await response.json;

        // redirect to "/cart"
        window.location.replace("/cart");

        // if (response.response.status === 422){
        //     return alert("Critical Error")
        // } else {
        //     console.log(data);
        //     for (let i = 0; i < items.length; i++) {
        //         var total_input = document.getElementById('item_total_' + items[i].id.split('_')[2])
        //         var new_total = Number(data[i].item_total).toFixed(2)
        //         total_input.innerHTML = '$' + new_total
        //     }
        //     document.getElementById('cart_items_total').innerHTML = '$' + Number(data[0].cart.items_total).toFixed(2)
        // }
  }

}
